
const getPins = () => {
  return new Promise((resolve, reject) => {
    fetch(`/pins`)
      .then(response => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const getPinImageUrl = (pinId) => {
  return new Promise((resolve, reject) => {
    fetch(`/pins/${pinId}/pin_image_url`)
      .then(response => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.log(err);
      });
  });
}

const getAnnotationCategories = () => {
  return new Promise((resolve, reject) => {
    fetch(`/annotation_categories`)
      .then(response => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export { getPins, getPinImageUrl, getAnnotationCategories }