import React from 'react';
import { createRoot } from "react-dom/client";
import PinMap from "../javascript/components/PinMap/PinMap";

let pinMapContainer = document.getElementById('pin-map-container');

document.addEventListener('DOMContentLoaded', () => {
  if (pinMapContainer) {
    const root = createRoot(pinMapContainer); 
    root.render(
      <PinMap/>
    );
  }
});