import React, { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { point } from "@turf/helpers";

import { PinMapContext } from "./PinMapProvider";

import {
  multipleChoiceOptions,
  iconOptions,
  generateRandomString,
  resizeImage,
  shuffleArray,
  toTranslationKey
} from "./utils/dataUtils";
import plusIcon from "../../../images/icons/icon_plus.png";

const CreatePin = () => {
  const {
    map,
    newPinData,
    setNewPinData,
    pinAnnotationCategories,
    mapHeight,
    isMobile,
    setPinFeatureCollection,
  } = useContext(PinMapContext);

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const [pinData, setPinData] = useState({
    latitude: newPinData.geometry.coordinates[1],
    longitude: newPinData.geometry.coordinates[0],
    annotation_values: [],
    image: null,
  });

  const [annotationCategoryIndex, setAnnotationCategoryIndex] = useState(0);
  const [currentAnnotationCategory, setCurrentAnnotationCategory] = useState(
    pinAnnotationCategories[0]
  );
  const [multipleChoiceOptionsShuffled, setMultipleChoiceOptionsShuffled] = useState(multipleChoiceOptions);

  const [isUploading, setIsUploading] = useState(false);

  const fileInputRef = useRef(null);
  const clickFileInput = () => fileInputRef.current.click();

  

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 5);

    setPinData((pinData) => ({
      ...pinData,
      annotation_values: pinAnnotationCategories.map((category) => ({
        annotation_category_id: category.id,
        values:
          category.annotation_type === "icon"
            ? [newPinData.properties.icon]
            : [],
      })),
    }));

    setMultipleChoiceOptionsShuffled(shuffleArray(multipleChoiceOptions));
  }, []);

  useEffect(() => {
    setCurrentAnnotationCategory(
      pinAnnotationCategories[annotationCategoryIndex]
    );
  }, [annotationCategoryIndex]);

  const handleImageChange = (e, categoryId) => {
    let file = e.target.files[0];

    setPinData((currentPinData) => {
      const updatedAnnotations = currentPinData.annotation_values.map(
        (annotation) => {
          if (annotation.annotation_category_id === categoryId) {
            return { ...annotation, values: ["custom_icon"] };
          }
          return annotation;
        }
      );

      return {
        ...currentPinData,
        image: file,
        annotation_values: updatedAnnotations,
      };
    });

    resizeImage(file, 512, 512, (resizedBlob) => {
      const imageUrl = URL.createObjectURL(resizedBlob);

      setNewPinData((newPinData) => ({
        ...newPinData,
        properties: {
          icon: "custom_icon",
          custom_icon: imageUrl,
          icon_rotation: 0,
        },
      }));

      addImageToMap(imageUrl);
    });
  };

  const handleChange = (value, categoryId = null) => {
    if (categoryId) {
      const updatedAnnotations = pinData.annotation_values.map((annotation) => {
        if (annotation.annotation_category_id === categoryId) {
          return { ...annotation, values: [value] };
        }
        return annotation;
      });
      setPinData({ ...pinData, annotation_values: updatedAnnotations });
    }
  };

  const handleMultipleChoiceChange = (choice, categoryId) => {
    setPinData((prev) => {
      const annotationsCopy = prev.annotation_values.map((annotation) => {
        if (annotation.annotation_category_id === categoryId) {
          const valueIndex = annotation.values.indexOf(choice);
          if (valueIndex > -1) {
            return {
              ...annotation,
              values: annotation.values.filter(
                (_, index) => index !== valueIndex
              ),
            };
          } else {
            return { ...annotation, values: [...annotation.values, choice] };
          }
        }
        return annotation;
      });

      return { ...prev, annotation_values: annotationsCopy };
    });
  };

  const handleIconChange = (icon, categoryId) => {
    handleChange(icon, categoryId);

    setNewPinData((newPinData) => ({
      ...newPinData,
      properties: { icon: icon },
    }));
  };

  const addImageToMap = (imageUrl) => {
    if (map && map.getStyle()) {
      map.loadImage(imageUrl, (error, image) => {
        if (error) throw error;
        if (!map.hasImage("custom_icon")) {
          map.addImage("custom_icon", image);
        } else {
          map.removeImage("custom_icon");
          map.addImage("custom_icon", image);
        }
      });
    }
  };

  const handleSubmit = () => {
    setIsUploading(true);
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    const formData = new FormData();

    formData.append("pin[latitude]", pinData.latitude);
    formData.append("pin[longitude]", pinData.longitude);

    let valueIndex = 0;

    pinData.annotation_values.forEach((valueCategory) => {
      valueCategory.values.forEach((value) => {
        formData.append(
          `annotation_values[${valueIndex}][annotation_category_id]`,
          valueCategory.annotation_category_id
        );
        formData.append(`annotation_values[${valueIndex}][value]`, value);
        valueIndex++;
      });
    });

    // Append image if present
    if (pinData.image) {
      formData.append("pin[image]", pinData.image);
    }

    fetch("/pins", {
      method: "POST",
      headers: {
        "X-CSRF-Token": token,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        handleNewPinAdded(data);
      })
      .catch((error) => {
        console.error("Error posting new pin:", error);
      });
  };

  const handleNewPinAdded = (pin) => {
    let iconName = generateRandomString();

    if (pin.image_url) {
      map.loadImage(pin.image_url, function (err, res) {
        if (err) console.log(err);
        map.addImage(iconName, res);
      });
    }

    let feature = point([pin.longitude, pin.latitude], {
      ...pin,
      annotation_values: pin.annotation_values,
      icon_rotation: 0,
      icon: pin.image_url
        ? iconName
        : pin.annotation_values.find(
            (v) => v.annotation_category.annotation_type == "icon"
          ).value,
    });

    setPinFeatureCollection((pinFeatureCollection) => ({
      ...pinFeatureCollection,
      features: [...pinFeatureCollection.features, feature],
    }));

    setIsUploading(false);
    setNewPinData(null);
  };

  let sidebarClassList = "map-sidebar";
  if (visible) sidebarClassList += " active";
  sidebarClassList += isMobile ? " mobile" : " desktop";

  let sidebarHeight = isMobile ? mapHeight - 150 : mapHeight;

  return (
    <div
      id="create-pin-container"
      className={sidebarClassList}
      style={{ height: sidebarHeight + "px" }}
    >
      {!isUploading && (
        <div
          id="close-create-pin"
          className="close-sidebar"
          style={{ cursor: "pointer", padding: "1rem" }}
          onClick={() => setNewPinData(null)}
        >
          ×
        </div>
      )}
      <div id="create-pin-content" className="sidebar-content">
        {isUploading && (
          <div className="loading-container">
            <div className="loading-animation-container">
              <div className="loading-element"></div>
              <div className="loading-element"></div>
              <div className="loading-element"></div>
            </div>
            <p className="bold my-1">{t("createPin.loadingHeadline")}</p>
            <p className="small">
              {t("createPin.loadingText")}
            </p>
          </div>
        )}

        {!isUploading && (
          <>
            <div key={currentAnnotationCategory.id}>
              <label>{t(toTranslationKey("createPin.annotations", currentAnnotationCategory.name))}</label>
              <p className="my-1 small">
                {t(toTranslationKey("createPin.annotations", currentAnnotationCategory.description))}
              </p>

              {currentAnnotationCategory.annotation_type === "text" && (
                <div className="type-text">
                  <input
                    type="text"
                    value={
                      pinData.annotation_values.find(
                        (val) =>
                          val.annotation_category_id ===
                          currentAnnotationCategory.id
                      )?.values || ""
                    }
                    onChange={(e) =>
                      handleChange(e, currentAnnotationCategory.id)
                    }
                    required
                  ></input>
                </div>
              )}

              {currentAnnotationCategory.annotation_type === "textarea" && (
                <div className="type-textarea">
                  <textarea
                    value={
                      pinData.annotation_values.find(
                        (val) =>
                          val.annotation_category_id ===
                          currentAnnotationCategory.id
                      )?.values || ""
                    }
                    onChange={(e) =>
                      handleChange(e.target.value, currentAnnotationCategory.id)
                    }
                    required
                  ></textarea>
                </div>
              )}

              {currentAnnotationCategory.annotation_type ===
                "multiple-choice" && (
                <div className="type-multiple-choice">
                  {multipleChoiceOptionsShuffled.map((option) => (
                    <div
                      key={option}
                      className="multiple-choice-option"
                      onClick={() =>
                        handleMultipleChoiceChange(
                          option,
                          currentAnnotationCategory.id
                        )
                      }
                    >
                      <span
                        className={
                          pinData.annotation_values
                            .find(
                              (a) =>
                                a.annotation_category_id ===
                                currentAnnotationCategory.id
                            )
                            ?.values.includes(option)
                            ? "checkbox checked"
                            : "checkbox"
                        }
                      ></span>
                      <span className="checkbox-label">{t('createPin.multipleChoiceOptions.'+option)}</span>
                    </div>
                  ))}
                </div>
              )}

              {currentAnnotationCategory.annotation_type === "icon" && (
                <div className="type-icon">
                  <div
                    key="image"
                    className="icon-option icon-option--image"
                    onClick={() =>
                      pinData.image &&
                      handleIconChange(
                        "custom_icon",
                        currentAnnotationCategory.id
                      )
                    }
                    style={
                      pinData.image
                        ? {
                            backgroundImage: `url(${URL.createObjectURL(
                              pinData.image
                            )})`,
                          }
                        : {}
                    }
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) =>
                        handleImageChange(e, currentAnnotationCategory.id)
                      }
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                    {!pinData.image && (
                      <img
                        src={plusIcon}
                        alt="Plus icon"
                        onClick={clickFileInput}
                      />
                    )}
                  </div>

                  {iconOptions.map((icon) => (
                    <div
                      key={icon.name}
                      className={
                        pinData.annotation_values
                          .find(
                            (a) =>
                              a.annotation_category_id ===
                              currentAnnotationCategory.id
                          )
                          ?.values.includes(icon.name)
                          ? "icon-option selected"
                          : "icon-option"
                      }
                      onClick={() =>
                        handleIconChange(
                          icon.name,
                          currentAnnotationCategory.id
                        )
                      }
                    >
                      <img src={icon.src} alt={icon.name} />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="mt-2 buttons-container w-100 flex justify-between">
              {annotationCategoryIndex > 0 ? (
                <button
                  className="btn btn-md"
                  onClick={() => setAnnotationCategoryIndex((prev) => prev - 1)}
                >
                  {t("createPin.previousButtonText")}
                </button>
              ) : (
                <div></div>
              )}

              {annotationCategoryIndex < pinAnnotationCategories.length - 1 && (
                <button
                  className="btn btn-md"
                  onClick={() => setAnnotationCategoryIndex((prev) => prev + 1)}
                >
                  {t("createPin.nextButtonText")}
                </button>
              )}

              {annotationCategoryIndex ==
                pinAnnotationCategories.length - 1 && (
                <button
                  className="btn btn-md btn-highlight"
                  onClick={() => handleSubmit()}
                >
                  {t("createPin.shareButtonText")}
                </button>
              )}
            </div>

            {annotationCategoryIndex ==
              pinAnnotationCategories.length - 1 && (
                <div className="my-2">
                <p className="small inline">{t("createPin.termsText")}</p> {" "}
                <a className="small bold" target="_blank" href="/pages/terms">{t("createPin.termsLinkText")}</a>
                </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CreatePin;
