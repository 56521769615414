import React, { useContext, useEffect, useState } from "react";

import { PinMapContext } from "./PinMapProvider";

import Introduction from "./Introduction";
import Header from "./Header";
import Map from "./Map";
import CreatePin from "./CreatePin";
import ShowPin from "./ShowPin";
import Footer from "./Footer";
import InfoBox from "./InfoBox";

import { getPins, getAnnotationCategories } from "./utils/api";

const PinMapInterface = () => {
  const {
    showIntro,
    mapLanguage,
    setPinData,
    setPinAnnotationCategories,
    newPinData,
    openPin,
    showInfoBox,
  } = useContext(PinMapContext);

  useEffect(() => {
    getPins().then((pins) => {
      setPinData(pins);
    });

    getAnnotationCategories().then((annotationCategories) => {
      setPinAnnotationCategories(annotationCategories);
    });
  }, []);

  return (
    <div id="pin-map-interface" data-language={mapLanguage} className={(newPinData || openPin) ? "sidebar-open" : ""}>
      {showIntro && <Introduction />}
      {!showIntro && (
        <>
          <Header />
          <Map />
          {newPinData && <CreatePin />}
          {openPin && <ShowPin />}
          {showInfoBox && <InfoBox />}
          <Footer />
        </>
      )}
    </div>
  );
};

export default PinMapInterface;
