import React, { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';

import { PinMapContext } from "./PinMapProvider";

const InfoBox = () => {
  const { setShowInfoBox } = useContext(PinMapContext);
  const { t } = useTranslation();

  return (
    <div id="info-box-container">
      <div
        id="close-info-box"
        className="close-sidebar"
        style={{ cursor: "pointer", padding: "1rem" }}
        onClick={() => setShowInfoBox(false)}
      >
        ×
      </div>
      <div id="info-box-content">
        <h1>{t('infoBox.title')}</h1>
        <p>
          {t('infoBox.text')}
        </p>

        <br />

        <p>
          {t('infoBox.dataText')} {' '}
          <a className="bold c-white" target="_blank" rel="noreferrer" href="/pages/terms">{t('infoBox.dataLinkText')}</a>.
        </p>
      </div>
    </div>
  );
};

export default InfoBox;
