import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { PinMapContext } from "./PinMapProvider";

import instructionIllustration from "../../../images/icons/illustration_instructions.png";

const Instructions = () => {
  const { setShowInstructions } = useContext(PinMapContext);
  const { t } = useTranslation();

  const [instructionsIndex, setInstructionsIndex] = useState(0);

  const instructions = [
    {
      headline: t("instructions.headline0"),
      text: t("instructions.text0"),
    },
    {
      headline: t("instructions.headline1"),
      text: t("instructions.text1"),
    },
    {
      headline: t("instructions.headline2"),
      text: t("instructions.text2"),
    },
    {
      headline: t("instructions.headline3"),
      text: t("instructions.text3"),
    },
    {
      headline: t("instructions.headline4"),
      text: t("instructions.text4"),
    },
  ];

  const handleNext = () => {
    if (instructionsIndex < instructions.length - 1) {
      setInstructionsIndex(instructionsIndex + 1);
    } else {
      setShowInstructions(false);
    }
  };

  const handleBack = () => {
    if (instructionsIndex > 0) {
      setInstructionsIndex(instructionsIndex - 1);
    }
  };

  const handleDotClick = (index) => {
    setInstructionsIndex(index);
  }

  const handleClose = () => {
    setShowInstructions(false);
  };

  return (
    <>
      <div id="disable-map-overlay" />
      <div id="instructions-container">
        <div id="instructions-content" style={{ padding: "1.5rem" }}>
          <button className="close-button" onClick={handleClose}>
            &times;
          </button>

          <div className="instructions-content-wrapper">
            <div>
              {instructionsIndex > 0 && (
                <div className="instruction-number">
                  <span>{instructionsIndex}</span>
                </div>
              ) }
              <div className="instruction-point">
                <div className="instruction-headline">
                  {instructions[instructionsIndex].headline}
                </div>
              </div>
              <p className="instruction-text">
                {instructions[instructionsIndex].text}
              </p>
            </div>

            <div className="instructions-navigation">
              {instructionsIndex > 0 ? (
                <button className="btn btn-md" onClick={handleBack}>
                  {t("instructions.backButtonText")}
                </button>
              ) : (
                <div className="blank"></div>
              )}
              <div className="navigation-dots-container">
                {instructions.map((instruction, index) => {
                  return (
                    <div
                      key={index}
                      className={`navigation-dot ${
                        index === instructionsIndex ? "active" : ""
                      }`}
                      onClick={() => handleDotClick(index)}
                    ></div>
                  );
                })}
              </div>
              <button className="btn btn-md" onClick={handleNext}>
                {instructionsIndex < instructions.length - 1
                  ? `${t("instructions.nextButtonText")}`
                  : `${t("instructions.finishButtonText")}`}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Instructions;
