import React, { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { PinMapContext } from "./PinMapProvider";
import { getPinImageUrl } from "./utils/api";

import { iconOptions, placeholderIcon } from "./utils/dataUtils";

const ShowPin = () => {
  const { openPin, setOpenPin, mapHeight, isMobile } =
    useContext(PinMapContext);
  const { t } = useTranslation();

  const [pinData, setPinData] = useState(null);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 5);
  }, []);

  useEffect(() => {
    if (!openPin) return;

    if (openPin.properties.image_url) {
      getPinImageUrl(openPin.properties.id).then((res) => {
        setPinData({
          ...openPin.properties,
          annotations: JSON.parse(openPin.properties.annotation_values),
          image_url: res.url,
        });
      });
    } else {
      setPinData({
        ...openPin.properties,
        annotations: JSON.parse(openPin.properties.annotation_values),
      });
    }
  }, [openPin]);

  if (!pinData) return;

  let sidebarClassList = "map-sidebar";
  if (visible) sidebarClassList += " active";
  sidebarClassList += isMobile ? " mobile" : " desktop";

  let sidebarHeight = isMobile ? mapHeight - 150 : mapHeight;

  return (
    <div
      id="show-pin-container"
      className={sidebarClassList}
      style={{ height: sidebarHeight + "px" }}
    >
      <div
        id="close-show-pin"
        className="close-sidebar"
        style={{ cursor: "pointer", padding: "1rem" }}
        onClick={() => setOpenPin(null)}
      >
        ×
      </div>
      <div id="show-pin-content" className="sidebar-content">
        <h1 className="mb-1">{t("showPin.title")}</h1>

        {pinData.image_url && (
          <img className="pin-image" src={pinData.image_url} />
        )}

        {!pinData.image_url && <img src=""></img>}
        {pinData.annotations?.map((annotation) => {
          if (
            annotation.annotation_category.annotation_type === "icon" &&
            !pinData.image_url
          ) {
            const iconSrc =
              iconOptions.find((i) => i.name === annotation.value)?.src ||
              placeholderIcon.src;
            return <img className="icon" src={iconSrc} key={iconSrc} ></img>;
          }

          if (annotation.annotation_category.annotation_type === "textarea") {
            return (
              <p className=" my-1 text international-font" key={annotation.value}>
                {annotation.value}
              </p>
            );
          }
        })}
      </div>
    </div>
  );
};

export default ShowPin;
