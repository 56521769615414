import React, { useContext, useEffect, useState, useRef } from "react";

import { PinMapContext } from "./PinMapProvider";

const Header = () => {
  
  return (
    <header>
      <div id="header-content" className="flex justify-between">
        <a className="ltr" href="/">Celebrate Madinah</a>
        <a className="rtl" href="/">احتفل بالمدينة المنورة</a>
      </div>
    </header>
  );
};

export default Header;
