import React, { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { PinMapContext } from "./PinMapProvider";

import titleImage from "./../../../images/title-image.png";
import gradientBlob from "./../../../images/gradient-blob.svg";

import LanguageSelector from "./LanguageSelector";

const Introduction = () => {
  const { setShowIntro } = useContext(PinMapContext);
  const { t } = useTranslation();

  return (
    <div id="introduction-container" className="p-1">
      <div className="w-100 relative row">
        <div id="graphics-container" className="relative w-100">
          <img src={gradientBlob} id="blob-image" className="w-100 absolute" />
          <img src={titleImage} id="title-image" className="w-100 absolute" />
        </div>
      </div>

      <div className="w-100 relative row">
        <div id="text-container" className="p-1">
          <h1>{t("introduction.title")}</h1>
          <p className="mt-1">{t("introduction.text")}</p>
          <p className="mt-1">{t("introduction.text2")}</p>
          <div className="mt-2" id="enter-map-btn-container">
            <button
              className="btn"
              onClick={() => {
                setShowIntro(false);
              }}
            >
              {t("introduction.enterButton")}
            </button>
          </div>
        </div>
      </div>

      <div id="language-choice-selector--introduction">
        <LanguageSelector />
      </div>
    </div>
  );
};

export default Introduction;
