import React, { useContext, useEffect, useState, useRef } from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

import { featureCollection, point } from "@turf/helpers";

const mapboxStyle = import.meta.env.VITE_MAPBOX_STYLE;
const mapboxToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

import { PinMapContext } from "./PinMapProvider";
import Instructions from "./Instructions";
import { addSourcesAndLayers } from "./utils/mapUtils";
import { generateRandomString, placeholderIcon } from "./utils/dataUtils";

const Map = () => {
  const {
    map,
    setMap,
    pinData,
    newPinData,
    setNewPinData,
    mapLanguage,
    openPin,
    setOpenPin,
    mapHeight,
    setMapHeight,
    showInstructions,
    isMobile, setIsMobile,
    pinFeatureCollection, setPinFeatureCollection
  } = useContext(PinMapContext);

  const mapContainerRef = useRef(null);
  const changeLanguageRef = useRef(null);
  const [lng, setLng] = useState(39.61);
  const [lat, setLat] = useState(24.47);
  const [zoom, setZoom] = useState(12);

  const [mapReady, setMapReady] = useState(false);

  
  const [currentStyle, setCurrentStyle] = useState(mapboxStyle);
  

  const handleResize = () => {
    setMapHeight(mapContainerRef.current?.clientHeight);
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    mapboxgl.accessToken = mapboxToken;
    mapboxgl.setRTLTextPlugin(
      "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js"
    );
    let tempMap = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: currentStyle,
      center: [lng, lat],
      zoom: zoom,
      maxBounds: [
        39.45891450753385,
        24.307773410596397,
        39.79962838173981,
        24.595717687202566
      ]
    });

    tempMap.addControl(new mapboxgl.NavigationControl(), "top-right");
    tempMap.dragRotate.disable();
    tempMap.touchZoomRotate.disableRotation();

    changeLanguageRef.current = new MapboxLanguage({
      defaultLanguage: mapLanguage,
    });
    tempMap.addControl(changeLanguageRef.current);

    let geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl,
      marker: false,
      placeholder: "Search for a place",
      bbox: [
        39.45891450753385,
        24.307773410596397,
        39.79962838173981,
        24.595717687202566
      ],
      types: "neighborhood, address, poi, locality",
      language: "en, ar",
    });
    geocoder.setFlyTo({ speed: 1.5 });
    tempMap.addControl(geocoder, "top-left");

    tempMap.on("move", () => {
      setLng(tempMap.getCenter().lng.toFixed(4));
      setLat(tempMap.getCenter().lat.toFixed(4));
      setZoom(tempMap.getZoom().toFixed(2));
    });

    tempMap.on("load", function () {
      addSourcesAndLayers(tempMap);
    });

    tempMap.on("idle", function () {
      setMapReady(true);
    });

    setMap(tempMap);

    handleResize(); // Initial height update
    window.addEventListener("resize", handleResize); // Add resize listener

    return () => {
      tempMap.remove();
      window.removeEventListener("resize", handleResize); // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    if (!map) return;

    map.on("mouseenter", ["pins_layer_circle", "pins_layer_icon"], function () {
      map.getCanvas().style.cursor = "pointer";
    });

    map.on("mouseleave", ["pins_layer_circle", "pins_layer_icon"], function () {
      map.getCanvas().style.cursor = "";
    });

    map.on("click", (e) => {
      let bbox = [
        [e.point.x - 10, e.point.y - 10],
        [e.point.x + 10, e.point.y + 10],
      ];

      var features = map.queryRenderedFeatures(bbox, {
        layers: ["pins_layer_circle", "pins_layer_icon"],
      });

      if (features.length > 0) {
        setOpenPin(features[0]);
        setNewPinData(null);
      } else {
        setNewPinData({
          geometry: { coordinates: [e.lngLat.lng, e.lngLat.lat] },
          properties: {
            icon: placeholderIcon.name
          },
        });
        setOpenPin(null);
      }
    });

    map.on("zoomstart", (e) => {
      // if(mapAlertRef.current.topic === 'zoom' || mapAlertRef.current.topic.includes('instruction')) setMapAlert({topic: '', message: ''});
    });

    map.on("movestart", (e) => {
      // if(mapAlertRef.current.topic.includes('instruction')) setMapAlert({topic: '', message: ''});
    });
  }, [map]);

  useEffect(() => {
    if (!map) return;
    if (!map || !map.isStyleLoaded()) return;

    map.setStyle(
      changeLanguageRef.current.setLanguage(map.getStyle(), mapLanguage)
    );
  }, [map, mapLanguage]);

  useEffect(() => {
    if (pinData.length <= 0 || !mapReady) return;

    let points = pinData.map((pin) => {
      let iconName = generateRandomString();

      if (pin.image_url) {
        map.loadImage(pin.image_url, function (err, res) {
          if (err) console.log(err);
          map.addImage(iconName, res);
        });
      }

      return point([pin.longitude, pin.latitude], {
        ...pin,
        annotation_values: pin.annotation_values,
        icon_rotation: 0,
        icon: pin.image_url
          ? iconName
          : pin.annotation_values.find(
              (v) => v.annotation_category.annotation_type == "icon"
            ).value,
      });
    });

    setPinFeatureCollection(featureCollection(points));
  }, [mapReady, pinData]);

  useEffect(() => {
    if (!map || !mapReady) return;
    map.getSource("pins").setData(pinFeatureCollection);
  }, [pinFeatureCollection, mapReady]);

  useEffect(() => {
    if (map === undefined) return;

    let newPinPoint = newPinData
      ? point(newPinData.geometry.coordinates, newPinData.properties)
      : {};
    let newPinsFeatureCollection = featureCollection([newPinPoint]);

    map.getSource("new-pins").setData(newPinsFeatureCollection);

    if (!newPinData) return;

    map.easeTo({
      center: newPinData.geometry.coordinates,
      padding: isMobile ? { bottom: mapHeight - 200 } : { left: 450 },
      zoom: zoom > 15.5 ? zoom : 15.5,
      duration: 1000,
    });
  }, [newPinData]);

  useEffect(() => {
    if (!map || !openPin) return;

    map.easeTo({
      center: openPin.geometry.coordinates,
      padding: isMobile ? { bottom: mapHeight - 200 } : { left: 450 },
      zoom: zoom > 15.5 ? zoom : 15.5,
    });
  }, [openPin]);

  return (
    <div id="pin-map-outer-container">
      <div ref={mapContainerRef}></div>
      {showInstructions && <Instructions />}
    </div>
  );
};

export default Map;
