import React, { useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { PinMapContext } from "./PinMapProvider";

import LanguageSelector from "./LanguageSelector";
import questionMarkIcon from "./../../../images/question-icon.svg";

const Footer = () => {
  const { mapLanguage, setShowInfoBox } = useContext(PinMapContext);
  const { t } = useTranslation();

  return (
    <footer>
      <div
        id="footer-content"
        className="flex justify-between align-items-center"
      >
        <LanguageSelector />

        <div
          id="info-box-toggle"
          className="cursor-pointer flex"
          onClick={() => setShowInfoBox(true)}
        >
          <p>{t("infoBox.readMoreButtonText")}</p>
          <img
            src={questionMarkIcon}
            className={mapLanguage === "ar" ? "flip" : ""}
            style={{ width: "24px", height: "24px", display: "block" }}
            alt=""
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
