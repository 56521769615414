import { iconOptions, placeholderIcon } from "./dataUtils";

export const addSourcesAndLayers = (map) => {

  iconOptions.forEach((icon) => {
    map.loadImage(icon.src, function (err, res) {
      if (err) console.log(err);
      map.addImage(icon.name, res);
    });
  });

  map.loadImage(placeholderIcon.src, function (err, res) {
    if (err) console.log(err);
    map.addImage(placeholderIcon.name, res);
  });

  map.addSource("pins", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });

  map.addLayer({
    id: "pins_layer_circle",
    type: "circle",
    source: "pins",
    paint: {
      "circle-radius": {
        base: 6,
        stops: [
          [12, 6],
          [22, 180],
        ],
      },
      "circle-color": '#000000',
      "circle-stroke-color": "white",
      "circle-stroke-width": 2,
    },
    minzoom: 0,
    maxzoom: 14
  });

  map.addLayer({
    id: "pins_layer_icon",
    type: "symbol",
    source: "pins",
    layout: {
      "icon-image": ["get", "icon"],
      "icon-rotate": ["get", "icon_rotation"],
      "icon-allow-overlap": true,
      "icon-size": [
        "interpolate", ["linear"], ["zoom"],
        14, 0.1,
        17, 0.35
      ],
    },
    minzoom: 14
  });

  map.addSource("new-pins", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });

  map.addLayer({
    id: "new-pins_layer",
    type: "symbol",
    source: "new-pins",
    layout: {
      "icon-image": ["get", "icon"],
      "icon-rotate": ["get", "icon_rotation"],
      "icon-size": [
        "interpolate", ["linear"], ["zoom"],
        14, 0.1,
        17, 0.35
      ],
    },
  });
};